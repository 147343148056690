import './vouchers.scss'
import React from 'react'
import _ from 'lodash'
import { VOUCHER_TYPES } from '../../../api/api-constants'
import LOCALE from './VoucherLocale'

export default class Vouchers extends React.Component {
    constructor() {
        super();
        this.state = { value: '', error: '', showToolTip: false };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleToolTip = this.toggleToolTip.bind(this);
    }

    componentDidUpdate() {
        const { appHeightChanged, iframeId } = this.props;
        if (window.parent != window) appHeightChanged(iframeId);
    }

    handleChange(e) {
        const { appLocale } = this.props;
        const value = e.target.value.toUpperCase();
        if (!this.isLetterOrNumber(value)) this.setState({ error: LOCALE[appLocale].error });
        else if (this.isKeyStrokeValid(value)) this.setState({ value, error: '' });
    }

    isKeyStrokeValid(input) {
        const { type, appLocale, voucherConstraints } = this.props;
        const constraints = type == VOUCHER_TYPES.BIOKLUBDK ? _.filter(voucherConstraints, (voucher) => { return voucher.type == type }) : voucherConstraints;
        if (type != VOUCHER_TYPES.DISCOUNT && constraints.length > 0) {
            const vouchers = _.split(input, ',');
            const isValid = _.every(vouchers, (voucher) => {
                if (voucher.length <= 5) return true; // only check after 5 chars on each voucher number
                return _.some(constraints, (constraint) => {
                    const startValid = voucher.startsWith(constraint.prefix);
                    const lengthValid = voucher.length <= constraint.length;
                    return startValid && lengthValid;
                });
            });

            if (!isValid) {
                this.setState({ error: LOCALE[appLocale].errorInvalidType });
                return false;
            } else return true;
        } else { //There are no constraints on this type and we proceeed.
            this.setState({ error: '' });
            return true;
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const { value } = this.state;
        const { type, vouchers, vouchersChanged } = this.props;
        if (type == VOUCHER_TYPES.DISCOUNT) {
            vouchersChanged(value);
            this.setState({ value: '' });
        } else if (this.isValidConstraints(value)) {
            let updatedVouchers = '';
            const voucherCodes = _.uniq(_.concat(_.split(value, ','), _.map(vouchers.active, 'barCode')));
            _.map(voucherCodes, (code) => { return updatedVouchers += code + ','; });
            updatedVouchers = _.trimEnd(updatedVouchers, ',');

            vouchersChanged(updatedVouchers);
            this.setState({ value: '' });
        }
    }

    isLetterOrNumber(input) {
        const regex = /^[A-Z0-9,]*$/;
        return regex.test(input);
    }

    isValidConstraints(input) {
        const { type, appLocale, voucherConstraints } = this.props;
        const constraints = type == VOUCHER_TYPES.BIOKLUBDK ? _.filter(voucherConstraints, (voucher) => { return voucher.type == type }) : voucherConstraints;
        if (constraints.length > 0) {
            const vouchers = _.split(input, ',');
            const isValid = _.every(vouchers, (voucher) => {
                return _.some(constraints, (constraint) => {
                    const startValid = voucher.startsWith(constraint.prefix);
                    const lengthValid = voucher.length == constraint.length;
                    return startValid && lengthValid;
                });
            });

            if (!isValid) {
                this.setState({ error: LOCALE[appLocale].errorInvalidType });
                return false;
            } else return true;
        } else { //There are no constraints on this type and we proceeed.
            this.setState({ error: '' });
            return true;
        }
    }

    getPlaceholder() {
        const { type, appLocale } = this.props;
        switch (type) {
            case VOUCHER_TYPES.GIFTCARD:
                return LOCALE[appLocale].placeholderGiftCard;
            case VOUCHER_TYPES.BIOKLUBDK:
                return LOCALE[appLocale].placeholderBioKlubDk;
            case VOUCHER_TYPES.DISCOUNT:
                return LOCALE[appLocale].placeholderDiscount;
        }
    }

    getConstraints() {
        const { type, voucherConstraints, appLocale } = this.props;
        const constraints = type == VOUCHER_TYPES.BIOKLUBDK ? _.filter(voucherConstraints, (voucher) => { return voucher.type == type }) : voucherConstraints;
        if (constraints.length > 1) {
            const mapped = _.map(constraints, (constraint, i) => {
                return <li key={i}>{constraint.name} {LOCALE[appLocale].toolTipStartsWith} <strong>{constraint.prefix}</strong></li>;
            });
            return <div className="tool-tip">{LOCALE[appLocale].toolTipMulti} {mapped}</div>;
        } else if (constraints.length > 0)
            return <div className="tool-tip">{LOCALE[appLocale].toolTipOne} {constraints[0].name} {LOCALE[appLocale].toolTipStartsWith} <strong>{constraints[0].prefix}</strong></div>;
    }

    toggleToolTip() {
        const { showToolTip } = this.state;
        if (showToolTip) this.setState({ showToolTip: false });
        else this.setState({ showToolTip: true });
    }

    render() {
        const { type, appLocale, inputInfo } = this.props;
        const { value, error, showToolTip } = this.state;
        const constraintInfo = type != VOUCHER_TYPES.DISCOUNT ? this.getConstraints() : null;
        const toolTip = type != VOUCHER_TYPES.DISCOUNT ? <div className="has-info" onClick={this.toggleToolTip}><label className="icon-info"></label></div> : null;

        return (
            <form className="VoucherInput">
                <input type="text" placeholder={this.getPlaceholder()} value={value} onChange={this.handleChange} />
                <input type="submit" className="send" value={LOCALE[appLocale].submit} onClick={this.handleSubmit} />
                {toolTip}
                {inputInfo ? <div className="input-info" dangerouslySetInnerHTML={{ __html: inputInfo }}></div> : null}
                {!error ? <div className={showToolTip ? 'slide-down' : 'slide-up'}>{constraintInfo}</div> : null}
                {error ? <div className="VoucherErrors">
                    <strong>{error}</strong>
                    {constraintInfo}
                </div> : null}
            </form>
        );
    }
} 